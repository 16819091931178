import React, { useEffect, useRef } from 'react';
import { Review } from '../review';
import { unreachable } from '~/ts-utils';
import ReviewForm from '../review-form/review-form';
import { useApi } from '../api-provider/use-api';
import { useConfiguration } from '../configuration/use-configuration';
import { AppPermissionState } from '../../../common/store/reviews/reviews-permissions';
import { useModal, DeleteCommentConfirmationModal } from '../modals';
import { isEqual, noop } from 'lodash';
import { ActionsMenu } from '../actions-menu';
import { share } from './share';
import ReviewFooter from '../review/review-footer';
import { triggerIosKeyboard } from '../../../common/services/ios-utils';
import { ReviewState } from '../../../common/store/reviews/reviews/review-state-types';
import type { Review as ReviewType } from '../../../controller/lib/reviews-api-types';
import { GalleryModal } from '../gallery/gallery-modal';
import { useTranslate } from '~reviews/Widget/hooks/use-translate';
import { useEnvironment } from '@wix/yoshi-flow-editor';
import { Badge, BadgePriority } from 'wix-ui-tpa/cssVars';
import ClockSmall from 'wix-ui-icons-common/on-stage/ClockSmall';
import { generateDeepLink } from '~reviews/common/services/query-params';
import { makeReviewHtmlId } from '~reviews/common/services/id-utils';
import { ModerationStatus } from '@wix/ambassador-reviews-v1-enriched-review/types';

const StatusBadge = ({ text, 'aria-label': ariaLabel }: { text: string; 'aria-label': string }) => (
  <Badge priority={BadgePriority.secondary} icon={<ClockSmall />} aria-label={ariaLabel}>
    {text}
  </Badge>
);

const ReviewStateSwitchNoMemo: React.FC<{
  reviewState: ReviewState;
  appPermissionState: AppPermissionState;
}> = ({ reviewState, appPermissionState }) => {
  const showModal = useModal();
  const { isMobile } = useEnvironment();

  const config = useConfiguration();
  const t = useTranslate();

  const {
    initReply,
    updateReview,
    cancelEditingReview,
    biCancelCreatingReview,
    biClickShowMoreBtn,
    biClickReplyBtn,
    biOpenMediaModal,
    biCloseMediaModal,
    biClickPublishReview,
    biFormValidationError,
  } = useApi((state, actions) => ({
    initReply: actions.initReply,
    updateReview: actions.updateReview,
    cancelCreatingReview: actions.cancelCreatingReview,
    cancelEditingReview: actions.cancelEditingReview,
    biCancelCreatingReview: actions.biCancelCreatingReview,
    biClickShowMoreBtn: actions.biClickShowMoreBtn,
    biClickReplyBtn: actions.biClickReplyBtn,
    biOpenMediaModal: actions.biOpenMediaModal,
    biCloseMediaModal: actions.biCloseMediaModal,
    biClickPublishReview: actions.biClickPublishReview,
    biFormValidationError: actions.biFormValidationError,
  }));
  const hasBeenUpdated = useRef(false);

  useEffect(() => {
    switch (reviewState.type) {
      case 'REVIEW_UPDATE_PENDING':
        hasBeenUpdated.current = true;
        return;
      case 'REVIEW_EDITING':
      case 'REVIEW_READY':
      case 'REVIEW_DELETE_PENDING':
        return;
      default: {
        throw unreachable(reviewState);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [reviewState.type]);
  switch (reviewState.type) {
    case 'REVIEW_READY': {
      const { review, reviewId, votes } = reviewState;
      const isReviewPublished = review.moderationStatus === ModerationStatus.APPROVED;
      const shouldShowReplyButton =
        appPermissionState.SET_REPLY && !reviewState.replyState && isReviewPublished;
      const BadgeComp = (() => {
        switch (review.moderationStatus) {
          case ModerationStatus.REJECTED:
            return (
              <StatusBadge
                text={t('review.rejected-badge')}
                aria-label={t('review.rejected-badge.aria-label')}
              />
            );
          case ModerationStatus.IN_MODERATION:
            return (
              <StatusBadge
                text={t('review.pending-badge')}
                aria-label={t('review.pending-badge.aria-label')}
              />
            );
          default:
            return null;
        }
      })();
      return (
        <Review
          key={reviewId}
          id={makeReviewHtmlId(reviewId)}
          reviewId={reviewId}
          content={review.content}
          statusBadge={BadgeComp}
          author={review.author}
          createdAt={review.createdDate as any}
          verified={review.verified}
          openMediaModal={(index) => {
            showModal((toggle) => (
              <GalleryModal
                onClose={() => {
                  biCloseMediaModal({ reviewId });
                  toggle();
                }}
                review={review}
                activeIndex={index}
              />
            ));
            biOpenMediaModal({ reviewId });
          }}
          footer={
            (config.votes !== 'disabled' || shouldShowReplyButton) && (
              <ReviewFooter
                onReply={() => {
                  initReply({ parentId: review.id });
                  biClickReplyBtn({ reviewId: review.id });
                  triggerIosKeyboard();
                }}
                showReplyButton={shouldShowReplyButton}
                votesState={votes}
                reviewId={reviewId}
              />
            )
          }
          actionsMenu={
            <ActionsMenuReviewReady
              appPermissionState={appPermissionState}
              reviewId={reviewId}
              review={review}
              isMobile={isMobile}
            />
          }
          onShowMoreClick={(isShowMore) => {
            biClickShowMoreBtn({ isShowMore, reviewId, origin: 'main_screen' });
          }}
          disableShowMore={hasBeenUpdated.current}
        />
      );
    }
    case 'REVIEW_DELETE_PENDING': {
      const { reviewId, review, votes } = reviewState;
      const shouldShowReplyButton =
        appPermissionState.SET_REPLY &&
        !reviewState.replyState &&
        review.moderationStatus === 'APPROVED';
      return (
        <Review
          key={reviewId}
          id={makeReviewHtmlId(reviewId)}
          reviewId={reviewId}
          content={review.content}
          author={review.author}
          createdAt={review.createdDate as any}
          footer={
            (config.votes !== 'disabled' || shouldShowReplyButton) && (
              <ReviewFooter
                onReply={() => {}}
                showReplyButton={shouldShowReplyButton}
                votesState={votes}
                reviewId={reviewId}
              />
            )
          }
          actionsMenu={<ActionsMenu showDummy />}
          disableShowMore={hasBeenUpdated.current}
          isPending={true}
          verified={review.verified}
        />
      );
    }
    case 'REVIEW_EDITING': {
      const { reviewId, review } = reviewState;
      return (
        <ReviewForm
          key={reviewId}
          id={makeReviewHtmlId(reviewId)}
          isEdit={true}
          isPending={false}
          isInList={true}
          initialContent={review.content}
          requireContact={false}
          onSubmit={({ content, updatedFields }) => {
            biClickPublishReview({ review: { content, id: reviewId }, isEdited: true });
            updateReview({
              content,
              reviewId,
              updatedFields,
            });
          }}
          onValidationFailed={(error) => {
            biFormValidationError({ reviewId, missingRequiredFields: error ?? [] });
          }}
          onCancel={(content) => {
            if (isEqual(content, review.content)) {
              return {
                type: 'SILENT_CANCEL',
                cleanup: () => {
                  biCancelCreatingReview({ review: { content, id: reviewId }, isEdited: true });
                  cancelEditingReview({ reviewId });
                },
              };
            } else {
              return {
                type: 'PROMPT',
                discard: 'CHANGES',
                onConfirm: () => {
                  biCancelCreatingReview({ review: { content, id: reviewId }, isEdited: true });
                  cancelEditingReview({ reviewId });
                },
              };
            }
          }}
        />
      );
    }
    case 'REVIEW_UPDATE_PENDING': {
      const { reviewId } = reviewState;
      const content = reviewState.review.content;
      return (
        <ReviewForm
          key={reviewId}
          id={makeReviewHtmlId(reviewId)}
          isPending={true}
          isInList={true}
          initialContent={content}
          requireContact={false}
          isEdit={true}
          onSubmit={() => {}}
          onCancel={() => ({ type: 'SILENT_CANCEL', cleanup: noop })}
        />
      );
    }
    default: {
      throw unreachable(reviewState);
    }
  }
};

const ActionsMenuReviewReady: React.FC<{
  reviewId: string;
  review: ReviewType;
  appPermissionState: AppPermissionState;
  isMobile: boolean;
}> = ({ reviewId, review, appPermissionState, isMobile }) => {
  const t = useTranslate();
  const showModal = useModal();
  const { editReview, deleteReview, biSelectReviewAction, biOpenReviewActions, showToast } = useApi(
    (_state, actions) => ({
      deleteReview: actions.deleteReview,
      editReview: actions.editReview,
      biSelectReviewAction: actions.biSelectReviewAction,
      biOpenReviewActions: actions.biOpenReviewActions,
      showToast: actions.showToast,
    }),
  );
  return (
    <ActionsMenu
      onShowMenu={() => biOpenReviewActions({ reviewId })}
      edit={
        appPermissionState.EDIT_REVIEW(review.author)
          ? {
              label: t('reviews-actions.edit'),
              action: () => {
                biSelectReviewAction({
                  action: 'edit',
                  reviewId,
                });
                editReview({ reviewId });
                triggerIosKeyboard();
              },
            }
          : undefined
      }
      delete={
        appPermissionState.DELETE_REVIEW(review.author)
          ? {
              label: t('reviews-actions.delete'),
              action: () => {
                biSelectReviewAction({
                  action: 'delete',
                  reviewId,
                });
                showModal((toggle) => (
                  <DeleteCommentConfirmationModal
                    isReply={false}
                    toggle={toggle}
                    onClose={(confirmed) => {
                      confirmed && deleteReview({ reviewId });
                    }}
                  />
                ));
              },
            }
          : undefined
      }
      share={{
        label: isMobile ? t('reviews-actions.share') : t('reviews-actions.copy-link'),
        action: () => {
          biSelectReviewAction({
            action: 'copy_link',
            reviewId,
          });
          share({
            url: (() => {
              return generateDeepLink({
                resourceId: review.resourceId,
                namespace: review.namespace,
                reviewId,
              });
            })(),
            isMobile,
            afterCopy: () =>
              showToast({
                skin: 'success',
                message: { type: 'TRANSLATION', key: 'toast.share-success' },
              }),
          });
        },
      }}
    />
  );
};

export const ReviewStateSwitch = React.memo(ReviewStateSwitchNoMemo);
