import { createSettingsParams, SettingsParamType } from '@wix/tpa-settings';
import { LayoutTypes } from '~commons/settings/constants/layout-options';
import { VerifiedStyleOptions } from '~commons/settings/constants/verified-style-options';
import { Sorting } from '~settings-commons/constants/sorting';

export type SettingsParamsTypes = {
  headerTitleHtmlTag: SettingsParamType.Text;
  defaultSorting: SettingsParamType.Text;
  reviewCount: SettingsParamType.Number;
  isHeaderTitleEnabled: SettingsParamType.Boolean;
  isReviewBarchartEnabled: SettingsParamType.Boolean;
  isReviewDateEnabled: SettingsParamType.Boolean;
  isFilterEnabled: SettingsParamType.Boolean;
  isSortEnabled: SettingsParamType.Boolean;
  isCardShadowEnabled: SettingsParamType.Boolean;
  layoutType: SettingsParamType.Text;
  verifiedStyleType: SettingsParamType.Text;
};

export type SettingsParams = typeof settingsParams;

const settingsParams = createSettingsParams<SettingsParamsTypes>({
  headerTitleHtmlTag: {
    type: SettingsParamType.Text,
    getDefaultValue: () => 'h2',
  },
  isFilterEnabled: { type: SettingsParamType.Boolean, getDefaultValue: () => true },
  isSortEnabled: { type: SettingsParamType.Boolean, getDefaultValue: () => true },
  reviewCount: {
    type: SettingsParamType.Number,
    getDefaultValue: () => 5,
  },
  isHeaderTitleEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  isReviewBarchartEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  defaultSorting: { type: SettingsParamType.Text, getDefaultValue: () => Sorting.Newest },
  isReviewDateEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => true,
  },
  isCardShadowEnabled: {
    type: SettingsParamType.Boolean,
    getDefaultValue: () => false,
  },
  layoutType: {
    type: SettingsParamType.Text,
    getDefaultValue: () => LayoutTypes.List,
  },
  verifiedStyleType: {
    type: SettingsParamType.Text,
    getDefaultValue: () => VerifiedStyleOptions.Badge,
  },
});

export default settingsParams;
